
const variableName = '_tv_searches';
const maxLastSearches = 4;

export function saveLastSearch(search)
{
    let lastSearches = JSON.parse(localStorage.getItem(variableName)) || [];
    if (!lastSearches.includes(search)) lastSearches.push(search);
    if (lastSearches.length > maxLastSearches) lastSearches.shift();
    localStorage.setItem(variableName, JSON.stringify(lastSearches));
}

export function getLastSearches()
{
    return JSON.parse(localStorage.getItem(variableName)) || [];
}

export function removeSearch(search)
{
    let lastSearches = JSON.parse(localStorage.getItem(variableName)) || [];
    let index = lastSearches.indexOf(search);
    if (index > -1) lastSearches.splice(index, 1);
    localStorage.setItem(variableName, JSON.stringify(lastSearches));
}